import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { Container, Col, Row } from 'react-bootstrap';

export default  (props) => {

    const data = useStaticQuery(
        graphql`
            query OpeningHoursQuery {
                prismicHomepage {
                    data {
                        office_hours {
                            document {
                                data {
                                    sprechzeiten {
                                        day {
                                            text
                                        }
                                        time {
                                            text
                                        }
                                        time_2 {
                                            text
                                        }
                                    }
                                titel {
                                    html,
                                    text
                                }
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const openingHours = data.prismicHomepage.data.office_hours.document[0].data

    return (
        <div id="openingHours">
            <h3>
                <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>&nbsp;{openingHours.titel.text}
            </h3>
            <ul >
                {openingHours.sprechzeiten.map((openingHour, index) => (
                    <li className="openingHours-item" key={index} >
                            {/* <div>
                            <span className="day">{openingHour.day.text}</span>
                          
                            <span className="time">
                                {openingHour.time.text}
                            </span>
                            </div> 
                            <div>
                            <span className="day2">{openingHour.day.text}</span>
                            <span>{openingHour.time_2.text}</span>
                            </div> */}
                            <Container>
                                <Row>
                                <Col xs={3} md={3} lg={3} className="day">
                                    {openingHour.day.text}
                                    
                                </Col >
                                <Col xs={9} md={9} lg={9}>
                                    {openingHour.time.text}
                                </Col >
                                </Row>
                                <Row>
                                    <Col xs={{ span: 9, offset: 3 }} md={{ span: 9, offset: 3 }} lg={{ span: 9, offset: 3 }} className="time">
                                    {openingHour.time_2.text}
                                    </Col>
                                </Row>
                            </Container>
                            
                    </li>
                ))}
            </ul>   
        </div> 
    )

}