import React from 'react';
import {Col, Row, Button, Nav, Container} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'



const MobileActionButtons =() =>{
    return(
         <Container fluid>
            <Row className="d-lg-none">
                <Col xs={6} style={{padding:0}}>
                    <Button className="actionButton border-right">
                        <Nav.Link href="tel:+49-3573-2355">
                            <FontAwesomeIcon icon={faPhoneAlt} /> Anrufen
                        </Nav.Link>
                    </Button>
                </Col>
                <Col style={{padding:0}}>
                    <Button xs={6} className="actionButton border-left">
                        <Nav.Link 
                            href="https://www.google.com/maps/dir//Rudolf-Breitscheid-Stra%C3%9Fe+37,+Senftenberg" 
                            target="_blank"
                            rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faMapMarkerAlt} /> Navigieren
                        </Nav.Link>
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}

export default MobileActionButtons

