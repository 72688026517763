import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {Col, Row, Container, Tabs, Tab, Carousel, Accordion, Card} from 'react-bootstrap'
import OpeningHours from "../components/opening-hours"
import MobileActionButtons from '../components/mobile-action-buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import SEO from "../components/seo"


const Home = (props) => {
    
    const data = useStaticQuery(graphql`
        query {
            prismicHomepage {  
                data {
                    header_1{
                        text
                    }
                    slogan_1 {
                        text
                    }
                    slogan_2{
                        text
                    }
                    slogan_3{
                        text
                    }
                    slogan_4{
                        text
                    }
                }
            }
            allPrismicHomepage {
                nodes {
                  data {
                    background_img_1 {
                        url
                    }
                    background_img_2 {
                        url
                    }
                    background_img_3 {
                        url
                    }
                    background_img_4 {
                        url
                    }
                    news_group {
                      news_item {
                        document {
                          data {
                            news_titel {
                              text
                            }
                            body {
                              ... on PrismicNewsContentBodyHtmlCode {
                                primary {
                                  html_code {
                                    text
                                  }
                                }
                                slice_type
                              }
                              ... on PrismicNewsContentBodyText {
                                primary {
                                  text {
                                    html
                                  }
                                }
                                slice_type
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              } 
        }
    `)
    
    const newsItems = data.allPrismicHomepage.nodes[0].data.news_group
    const _newItems = newsItems.map(item => {
        const news_item = item.news_item.document[0].data
        const _newsItem = {
            titel: news_item.news_titel.text,
            contentItems: news_item.body.map(contentItem => {    
                const _contentItem = {
                    type: contentItem.slice_type,
                    content: contentItem.slice_type === 'html-code' ?  contentItem.primary.html_code.text : contentItem.primary.text.html
                }
                return _contentItem
            })
        }
        return _newsItem
    })    
    const id = "home";

    return (
        <>
            <SEO title="Home"/>
            <div className="hidden-md-down" id={id}>
                <MobileActionButtons />      
                <Container fluid> 
                    <ImageCarousel data={data}/>                 
                </Container>
                <Container>
                    <Newsbox data={_newItems}/>
                </Container>
                <Container className="contact d-md-none" >
                    <Contact />
                </Container>
            </div>   
        </>
    )
}

const ImageCarousel = (props) => {
    
    const data = props.data

    const carouselItemList = [
        {
            url:`${data.allPrismicHomepage.nodes[0].data.background_img_1.url}`,
            header:`${data.prismicHomepage.data.header_1.text}`,
            slogan:`${data.prismicHomepage.data.slogan_1.text}`,
        },
        {
            url:`${data.allPrismicHomepage.nodes[0].data.background_img_2.url}`,
            header:``,
            slogan:`${data.prismicHomepage.data.slogan_2.text}`,
        },
        {
            url:`${data.allPrismicHomepage.nodes[0].data.background_img_3.url}`,
            header:``,
            slogan:`${data.prismicHomepage.data.slogan_3.text}`,
        },
        {
            url:`${data.allPrismicHomepage.nodes[0].data.background_img_4.url}`,
            header:``,
            slogan:`${data.prismicHomepage.data.slogan_4.text}`,
        },
    ]

    return (       
        <div> 
            <Carousel>        
                {carouselItemList.map((carouselItem, index) => (
                    <Carousel.Item key={index}> 
                        <img className="d-block w-100 img-fluid"
                            src={carouselItem.url}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <Container className="caption">
                            <h1>{carouselItem.header}</h1>
                            <h5 className="d-none d-sm-block">{carouselItem.slogan}</h5>
                            </Container>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}                        
            </Carousel> 
        </div>
    )
}

const Newsbox = (props) => {

    const newsList = props.data

        return(
            <Row>
            <Col xs={12} lg={3} md={6} className="openingHoursContainer">
                <OpeningHours></OpeningHours>
            </Col>
            <Col md={9} className="d-none d-md-block">
                <Tabs>
                    {newsList.map((news, index) => (
                        <Tab key={index} 
                             eventKey={index} 
                             title={news.titel}>
                                 {news.contentItems.map((contentItem, index) => (
                                     <div key={index} dangerouslySetInnerHTML={{__html:contentItem.content}}></div>
                                 ))}
                        </Tab>
                    ))}    
                </Tabs>
            </Col>        
            <Col md={9} className="d-md-none">
                <Accordion defaultActiveKey="0">
                    {newsList.map((news, index) => (
                        <Card key={index}>
                            <Accordion.Toggle as={Card.Header} eventKey={index}>
                                {news.titel}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={index}>
                                <Card.Body>
                                    {news.contentItems.map((contentItem, index) => (
                                        <div key={index } dangerouslySetInnerHTML={{__html:contentItem.content}}></div>
                                    ))}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))} 
                </Accordion>
            </Col> 
        </Row>
        )
    }

const Contact = () => {

    return(
        <>
            <Row>
                <Col>
                    Kontakt:
                </Col>
            </Row>
            <Row>
                <Col>
                    <FontAwesomeIcon icon={faPhoneAlt} />  +49 (0) 3573/2355
                </Col>
            </Row>
            <Row>
                <Col>
                    <FontAwesomeIcon icon={faMapMarkerAlt} /> Rudolf-Breitscheidstraße 37, 01968 Senftenberg
                </Col>
            </Row>
        </>
    )
}

export default Home;